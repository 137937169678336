
    <template>
   <v-layout row class="justify-center">
  <v-card flat max-width="600px" min-width="400px" class="stickytopconscious" style="padding-bottom:50px;">
    <v-card-title>New User Registration</v-card-title>
     
        <v-card-text>
            
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="email" label="Email"></v-text-field>
                    <v-text-field v-model="password" label="Password"></v-text-field>
                    <v-select label="Select Title" v-model="Title" :items="TitleArray" item-text="Name"></v-select>                    
                    <v-text-field v-model="Name" label="Name"></v-text-field>
                    <v-text-field v-model="Surname" label="Surname"></v-text-field>
                    <v-text-field readonly filled v-model="UserFullName" label="FullName"></v-text-field>
                    <v-select label="Select Gender" v-model="Gender" :items="GenderArray" item-text="Name"></v-select>
                      
                  </v-col>
                </v-row>
             
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>  
              <v-btn @click="SignupwithFacebook()">Facebook</v-btn>            
              <v-btn color="blue darken-1" text @click="signUp()">Sign Up</v-btn>
              <span>or go back to <router-link to="/login">login</router-link>.</span>
            </v-card-actions>
        <!-- <div class="sign-up" id="signup-form">
    <p>Let's create a new account !</p>
    <input type="text" v-model="email" placeholder="Email"><br>
    <input type="password" v-model="password" placeholder="Password"><br>
    <v-select label="Select Title" v-model="Title" :items="TitleArray" item-text="Name"></v-select>
<br>
    <v-select label="Select Gender" v-model="Gender" :items="GenderArray" item-text="Name"></v-select>
<br>
    <input type="text" v-model="Name" placeholder="Name"><br>
    <input type="text" v-model="Surname" placeholder="Surname"><br>
  
<br>

    <button @click="signUp()">Sign Up</button>
    <span>or go back to <router-link to="/login">login</router-link>.</span>
  </div> -->
     
  </v-card>
   </v-layout>
</template>

  <script>
import firebase from 'firebase';
import db from '@/main'

  export default {
    name: 'signUp',
    data() {
      return {
        email: '',
        password: '',
        Title: '',

        Name: '',
        Surname: '',
        BusinessUnit: null,
        EmployeeCode: '',
        Position: '',
        BusinessUnits: [
          {index: 0, Name: 'Bambalela'},
          {index: 1, Name: 'Jordye'},
          {index: 2, Name: 'UBO'},
          {index: 3, Name: 'Group'},
          {index: 4, Name: 'Administration'},

        ],
        TitleArray: [
          {index: 0, Name: 'Mr'},
          {index: 1, Name: 'Mrs'},
          {index: 2, Name: 'Ms'},

        ],
        GenderArray: [
          {index: 0, Name: 'Male'},
          {index: 1, Name: 'Female'},

        ],
        Gender: null
      }
    },

    computed: {
      UserFullName(){
      return this.Name+' '+this.Surname
    },
      userLoggedIn () {
      return this.$store.getters.user
    }
    },

    watch: {
      userLoggedIn (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },

    created() {

    },

    methods: {
      SignupwithFacebook(){
        let FBprovider = new firebase.auth.FacebookAuthProvider();
        let vm = this
        firebase
        .auth()
          .signInWithPopup(FBprovider)
            .then((result) => {
              var credential = result.credential;
              var user = result.user;
              console.log(user)
            })
      },

     signUp() {
      const user = {
        email: this.email,
        password: this.password,
        Title: this.Title,
        Name: this.Name,
        Surname: this.Surname,
        Gender: this.Gender,
        IsGuestUser: true,
      }

      this.$store.dispatch('signUpAction', user)
      // this.$store.dispatch('createUserDoc', userdoc)

    }

    }
  }

</script>


 <style scoped>
  .sign-up {
    margin-top: 40px;
  }
  input {
    margin: 10px 0;
    width: 20%;
    padding: 15px;
  }
  button {
    margin-top: 10px;
    width: 10%;
    cursor: pointer;
  }
  span {
    display: block;
    margin-top: 20px;
    font-size: 11px;
  }
</style>

  


  